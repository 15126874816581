import React from 'react';

const getPositionClass = (position) => {
  if (position < 0) {
    return 'before';
  }
  if (position > 0) {
    return 'after';
  }
  return 'active';
};

export default ({ title, index, description, activeIndex }) => {
  const positionClass = getPositionClass(index - activeIndex);
  const className = `slide slide-r ${positionClass}`;
  const counterClassName = index === activeIndex ? 'active' : '';
  return (
    <div className={className}>
      <div className="slide-content">
        <h1 className={`slide-content-title ${positionClass}`}>{title}</h1>
        <p className={`slide-content-description ${positionClass}`}>{description}</p>

      </div>

      <div className="slide-counter">
        <p className={counterClassName}>{`${index + 1}/6`}</p>
      </div>
    </div>


  );
};