import React from 'react';
import Img from "gatsby-image";

export default ({ img, imgUrl, alt, active }) => {

  const className = `slide-image ${active ? 'active' : ''}`;

  return (
    // <div className="slide-image-holder">
    //   {img}
    // </div>

    // <>
    //   {img}
    // </>

    <Img
      style={{
        position: 'absolute',
      }}
      className={className}
      fluid={imgUrl}
      fadeIn={false}
      alt={alt}
    />
  );
};