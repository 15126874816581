import React from 'react';
import Layout from '../js/components/Layout';
import ServicesCarousel from '../js/screens/ServicesScreen/components/ServicesCarousel/ServicesCarousel';
import SEO from '../components/SEO';

export default () => {

  return (
    <Layout floatingNavbar footerHidden>
      <SEO
        title={"What We Do | Green Culture Studio Landscape Design"}
        description={"Lean about the services we provide and how we can help you with your landscape project."}
      />
      <ServicesCarousel />
    </Layout>
  );
};
