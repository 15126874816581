import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image";
import Swipe from 'react-easy-swipe';
import Slider from "react-slick";
import ServiceSlide from './components/ServiceSlide/ServiceSlide';
import ServiceImage from './components/ServiceImage/ServiceImage';
import SlideButton from './components/SlideButton/SlideButton';

class ServicesCarousel extends React.Component {
  state = {
    activeIndex: 0,
    scrollAllowed: true,
  };

  goUp = () => {
    const { scrollAllowed } = this.state;
    if (!scrollAllowed) {
      return;
    }

    this.setState(prevState => ({
      activeIndex: Math.max(0, prevState.activeIndex - 1),
      scrollAllowed: false,
    }));
    setTimeout(() => {
      this.setState({
        scrollAllowed: true,
      });
    }, 1000);
  }

  goDown = () => {
    const { scrollAllowed } = this.state;
    if (!scrollAllowed) {
      return;
    }

    this.setState(prevState => ({
      activeIndex: Math.min(5, prevState.activeIndex + 1),
      scrollAllowed: false,
    }));
    setTimeout(() => {
      this.setState({
        scrollAllowed: true,
      });
    }, 1000);
  }

  onSwipeUp = (event) => {
    this.goDown();
  }
 
  onSwipeDown = (event) => {
    this.goUp();
  }

  componentDidMount = () => {
    this.prev = window.scrollY;
    window.addEventListener('wheel', e => this.handleWheel(e));
  }

  componentWillUnmount = () => {
    window.removeEventListener('wheel', e => this.handleWheel(e));
  }

  handleWheel = (e) => {
    const { scrollAllowed } = this.state;

    if (!scrollAllowed) {
      return;
    }

    if (e.deltaY > 0) {
      this.goDown();
    } else if (e.deltaY < 0) {
      this.goUp();
    }

  }

  handleSlideButtonClick = (index) => {
    this.setState({
      activeIndex: index,
    });
  }

  render = () => {

    const { data } = this.props;
    const { activeIndex } = this.state;

    const slides = [
      {
        title: "Landscape Site Analysis",
        description: "First step in designing is to examine current site conditions, restrictions, possibilities and evaluate existing soft and hardscape. Adopting an environmental approach based on research about climate, natural vegetation and type of soil is a must.",
        imgUrl: data.site.childImageSharp.fluid,
        imgAlt: "Hand holding a tape measure above wooden fence.",
      }, {
        title: 'Landscape and Planting Design',
        description: 'Convert your ideas into reality! We design outdoor spaces at a variety of scales, from private residential landscapes to green public spaces. Each project is unique and has its own sense of place.',
        imgUrl: data.landscapeDesign.childImageSharp.fluid,
        imgAlt: 'Aerial short of a backyard with pebles, plants and stone pathways.',
      }, {
        title: 'Urban Design',
        description: 'Urban ecology and planning principles are fundamental to restoring the balance between city and nature. Public urban spaces such as city squares, pedestrian zones, alleys and green belts are some of the most important cityscapes.',
        imgUrl: data.urdbanDesign.childImageSharp.fluid,
        imgAlt: 'Aerial shot of an intersection with skyscrapers, parks, playgrounds and basketball pitch.',
      }, {
        title: 'Visualization',
        description: 'Our areas of expertise include creating 3D computer models, photorealistic digital renderings and walkthrough videos. We aim to convey our ideas to clients and present how the final project would look like after construction.',
        imgUrl: data.visualization.childImageSharp.fluid,
        imgAlt: '3d render of a backyard with a curved pool and sunbeds surrounded by plants.',
      }, {
        title: 'Sustainability',
        description: 'Focus of our design is creating sustainable and low maintenance green spaces on social, economic and ecological level. We encourage people to think about incorporating sustainability in everyday life.',
        imgUrl: data.sustainability.childImageSharp.fluid,
        imgAlt: 'Various plants and yellow and purple flowers.',
      }, {
        title: 'Technical Documentation',
        description: 'Detailed and well-written documentation is crucial for the construction phase. We pay equal attention to textual, construction details and financial components of the project.',
        imgUrl: data.technical.childImageSharp.fluid,
        imgAlt: 'Stack of papers containing technical drawings and numbers.',
      },
    ];

    return (

      <Swipe
        onSwipeUp={this.onSwipeUp}
        onSwipeDown={this.onSwipeDown}>

        <div className="row services-container justify-content-center">
          <div className="d-none d-md-block col-md-1 slide-button-container">
            <div className="slide-button-holder">
              <div className="slide-buttons">
                {slides.map((slide, index) => (
                  <SlideButton active={activeIndex === index} index={index} onClick={() => this.handleSlideButtonClick(index)} />
                ))
                }
              </div>
            </div>

          </div>
          <div className="d-none d-md-block col-md-6 col-sm-10 order-md-2 slide-image-container slide-image-container-r">
            <div className="slide-image-holder">
              {slides.map((slide, index) => (
                <ServiceImage
                  imgUrl={slide.imgUrl}
                  active={activeIndex === index}
                  alt={slide.imgAlt}
                />
              ))
              }

            </div>

          </div>
          <div className="d-none d-md-block col-md-5 order-md-1">
            {slides.map((slide, index) => (
              <ServiceSlide
                title={slide.title}
                description={slide.description}
                activeIndex={activeIndex}
                index={index}
              />
            ))
            }
          </div>

          <div className="d-md-none col-12 px-0 horizontal-slider-container">
            <Slider
              className="horizontal-slider"
              infinite={false}
              centerPadding={'1rem'}
              slidesToShow={1}
              slidesToScroll={1}
            >
              {slides.map((slide, index) => (
                <div className="horizontal-slide">
                  <Img
                    className="horizontal-slider-image"
                    fluid={slide.imgUrl}
                    // fadeIn={false}
                    alt={slide.imgAlt}
                  />
                  <p className="slide-counter">{`${index + 1}/6`}</p>
                  <h1 className={'slide-content-title'}>{slide.title}</h1>
                  <p>{slide.description}</p>

                </div>
              ))}
            </Slider>
          </div>
        </div>

      </Swipe>
    );
  }
};

export default props => (
  <StaticQuery
    query={graphql`
    query {
      site: file(relativePath: { eq: "site-analysis.jpg" }) {
        childImageSharp {
          fluid(sizes: """
            (max-width: 30px) 300px,
            (max-width: 600px) 600px,
            (max-width: 1000px) 1000px,
            (max-width: 1200px) 1200px,
            (max-width: 1500px) 1500px,
            (max-width: 2000px) 2000px,
            2000px""", 
            quality: 95, fit: COVER
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landscapeDesign: file(relativePath: { eq: "landscape-and-planting-design.jpg" }) {
        childImageSharp {
          fluid(sizes: """
            (max-width: 30px) 300px,
            (max-width: 600px) 600px,
            (max-width: 1000px) 1000px,
            (max-width: 1200px) 1200px,
            (max-width: 1500px) 1500px,
            (max-width: 2000px) 2000px,
            2000px""", 
            quality: 95, fit: COVER
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      urdbanDesign: file(relativePath: { eq: "urban-design.jpg" }) {
        childImageSharp {
          fluid(sizes: """
            (max-width: 30px) 300px,
            (max-width: 600px) 600px,
            (max-width: 1000px) 1000px,
            (max-width: 1200px) 1200px,
            (max-width: 1500px) 1500px,
            (max-width: 2000px) 2000px,
            2000px""", 
            quality: 95, fit: COVER
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      visualization: file(relativePath: { eq: "vis.png" }) {
        childImageSharp {
          fluid(sizes: """
            (max-width: 30px) 300px,
            (max-width: 600px) 600px,
            (max-width: 1000px) 1000px,
            (max-width: 1200px) 1200px,
            (max-width: 1500px) 1500px,
            (max-width: 2000px) 2000px,
            2000px""", 
            quality: 95, fit: COVER
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sustainability: file(relativePath: { eq: "sustainability.jpg" }) {
        childImageSharp {
          fluid(sizes: """
            (max-width: 30px) 300px,
            (max-width: 600px) 600px,
            (max-width: 1000px) 1000px,
            (max-width: 1200px) 1200px,
            (max-width: 1500px) 1500px,
            (max-width: 2000px) 2000px,
            2000px""", 
            quality: 95, fit: COVER
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      technical: file(relativePath: { eq: "technical-documentation.jpg" }) {
        childImageSharp {
          fluid(sizes: """
            (max-width: 30px) 300px,
            (max-width: 600px) 600px,
            (max-width: 1000px) 1000px,
            (max-width: 1200px) 1200px,
            (max-width: 1500px) 1500px,
            (max-width: 2000px) 2000px,
            2000px""", 
            quality: 95, fit: COVER
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    `}
    render={data => <ServicesCarousel data={data} {...props} />}
  />
);